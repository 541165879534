.reg-steps-container {
  width: 856px;
	padding: 133px 0 133px 0;
}

.reg-steps-container h1 {
  color:#374448;
  font-size: 36px;
  font-weight: 500;
  margin-bottom: 19px;
}

.reg-steps-container .text-div {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 1.4;
}

.steps-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  color: #828282;
  margin-bottom: 40px;
  position: relative;
}

.steps-list-line {
  display: none;
}

.steps-list li {
  padding: 10px 5px;
  cursor: pointer;
  font-weight: 500;
  display: block;
	user-select: none;
  -moz-user-select: none; 
  -webkit-user-select: none; 
}

.steps-list-past-step {
  color: #363636;
  font-weight: 500;
}

.steps-list-active-step {
  color: $primaryColor;
  border-bottom: 3px solid $primaryColor;
}

.reg-step-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.reg-step-form .reg-link {
  margin: -10px 0 59px;
  font-size: 14px;
  font-weight: 500;
}

.reg-step-form h2 {
  margin-bottom: 30px;
  margin-top: 60px;
  font-weight: 500;
}

.reg-step-form h2:first-of-type {
  margin-top: 0;
}

.reg-step-form h3 {
  margin-bottom: 25px;
  margin-top: 40px;
  font-weight: 500;
}

.small-screen-h2 {
  display: none;
}

.app-btn.inline-flex-reg-btn {
  display: inline-flex;
  margin-bottom: 0;
}

.large-screen-dlt {
  display: none;
}

.reg-steps-container .app-btn-wrap {
  margin-top: 53px;
}

.info-block {
  width: 100%;
  background: #F0FAFF;
  border-radius: 6px;
  padding: 16px;
  margin: 0px 0 22px;
  max-width: 400px;
}

.info-block .title-div {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  margin-bottom: 8px;
}

.info-block .text-div {
  font-size: 13px;
  line-height: 15px;
}

.row-of-inputs {
  display: flex;
  width: 100%;
  max-width: 400px;
  column-gap: 40px;
  -webkit-column-gap: 40px;
  margin-bottom: 15px;
  align-items: center;
}

.row-of-inputs.without-max-width {
  max-width: unset;
}

.row-of-inputs.first-step {
  max-width: 400px;
  column-gap: 32px;
  -webkit-column-gap: 32px;
}

.row-of-inputs.reverse-inputs-length .app-label {
  flex-grow: 1;
}

.row-of-inputs.reverse-inputs-length .app-label:first-of-type {
  flex-grow: 7;
}

.row-of-inputs.first-step .app-label:first-of-type {
  width: 60px;
}

.row-of-inputs > .app-label {
  width: 175px;
}

.row-of-inputs.without-max-width > .app-label {
  max-width: unset
}

.row-of-inputs:not(.reverse-inputs-length) > .app-label:last-of-type {
  flex-grow: 1;
}

.row-of-inputs > .app-label select {
  border: none;
  padding: 10px 0;
  border-bottom: solid #E0E0E0 1px;
  width: 100%;
}

.row-of-inputs > .app-label > label {
  width: 100%;
  display: block;
}

.row-of-inputs.my-dropzone-row {
  column-gap: 30px;
  -webkit-column-gap: 30px;
  margin-bottom: 30px;
  max-width: unset;
  flex-wrap:wrap
}

.my-dz {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.my-dz-text {
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.dz-container {
  display: flex;
}

.dz-message {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 160px;
  border: 1px dashed $primaryColor;
  box-sizing: border-box;
  border-radius: 4px;
  color: #6F787B;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 15px;
  cursor: pointer;
}

.done-page-document .dz-container {
  align-items: center;
  column-gap: 23px;
}

.done-page-document .dz-message {
  width: 216px;
  height: 38px;
  color: #374448;
  margin-bottom: 0;
  justify-content: stretch;
  align-items: stretch;
}

.dz-message:focus, .dropzone:focus {
  outline: none;
  border: 2px dashed $primaryColor;
}

.my-dz:last-of-type {
  margin-right: 0;
}

.large-dz-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 12px;
  line-height: 16px;

  &.file-too-large {
    color: #D40E14
  }
}

.large-dz-message div:last-of-type{
  width: 130px;
}

.large-dz-message span {
  color: $primaryColor;
}

.large-dz-message-image {
  width: 56px;
  height: 56px;
  background: url('../img/files-uploading.png') no-repeat 50% 50%;
}

.file-too-large .large-dz-message-image {
  background-image: url('../img/red-cross.png');
  background-size: 100%;

}

.dz-message:last-of-type:not(:first-of-type) {
  margin-bottom: 0;
}

.without-files {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.files-loading {
  background: url("../img/files-uploading.png") no-repeat 50% 50%;
  width: 56px;
  height: 56px;
  margin-bottom: 14px;
}

.new-loaded_files-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.new-loaded_file {
  cursor: auto;
  width: 109px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4px;
  margin: 25px 24px 20px;
}

.new-loaded_file-img {
  background: url("../img/files-done.png") no-repeat 50% 50%;
  width: 51px;
  height: 56px;
}

.new-loaded_file-name {
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #000000;
  text-align: center;
  margin-bottom: 4px;
  margin-top: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.new-loaded_file-type {
  text-transform: uppercase;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  color: #6F787B;
  overflow: hidden;
  text-overflow: ellipsis;
}

.new-loaded_file-close-btn {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -5px;
  right: 12px;
  padding: 5px;
  cursor: pointer;
}


@media( max-width: 1024px) {
  .large-screen-dlt {
    display: inline;
  }

  .reg-steps-container {
		max-width: 560px;
		min-width: 320px;
    width: 60%;
    padding: 99px 0;
  }

  .reg-steps-container h1 {
    font-size: 22px;
  }

  .reg-steps-container h2 {
    margin-top: 10px;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .reg-steps-container h3 {
    font-size: 16px;
    line-height: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .steps-list {
    width: 167px;
  }

  .steps-list-line {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .steps-list li{
    position: relative;
    font-size: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #E0E0E0;
    color: #fff;
  }

  .steps-list li a {
    padding: 0;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
  }

  ul li.steps-list-active-step {
    border: none;
    background-color:$primaryColor
  }

  li.steps-list-past-step {
    background-color: #828282;
  }

  .steps-list li:before {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    content: attr(num);
  }
  
  .small-screen-h2 {
    display: block;
    font-size: 18px;
    color: #374448;
    font-weight: 500;
    margin-bottom: 15px;
  }

  .reg-steps-container .app-btn-wrap {
    display: inline-flex;
    margin-top: 43px;
  }

  .info-block .title-div {
    font-size: 13px;
    line-height: 16px;
  }

  .info-block .text-div {
    font-size: 12px;
    line-height: 15px;
  }

  .row-of-inputs {
    flex-direction: column;
    margin-bottom: 0;
    align-items: flex-start;
  }

  .without-media.row-of-inputs {
    flex-direction: row;
    align-items: center;
  }

  .row-of-inputs.first-step .app-label:first-of-type {
    width: 100%;
    margin-right: 0;
  }
  
  .reg-step-form .row-of-inputs:nth-of-type(5) input{
    margin-bottom: 0;
  }

  .row-of-inputs > .app-label {
    width: 100%;
  }

  .row-of-inputs.my-dropzone-row {
    margin-bottom: 30px;
    flex-wrap: nowrap;
  }

  .my-dz {
    margin-right: 0;
    flex-direction: column-reverse;
    align-items: start;
    margin-bottom: 0;
    width: 100%;
    
    &.my-dz-done-page {
      flex-direction: column;
      align-items: center;
    }
  }

  .dz-container {
    width: 100%;
  }
  
  .dz-message {
    width: 100%;
    margin-bottom: 20px;
  }

  .my-dz-text {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 16px;
    color: #6f786b;
    font-weight: bold;
  }

  .small-dz-message {
    width: 70%;
    text-align: center;
    min-width: 180px;
  }

  .my-dz:last-of-type > div {
    margin-bottom: 10px;
  }
}